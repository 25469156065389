import React, { useEffect, useState } from "react";
import { ZoomMtg } from "@zoomus/websdk";

import Zoom from "./Embed";

const ZOOM_FILES_URL = "https://static-ai.nyc3.digitaloceanspaces.com/univai/public/lib";
ZoomMtg.setZoomJSLib(ZOOM_FILES_URL, "/av");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

export default function ZoomInit() {
  const [zoomProps, setzoomProps] = useState();
  const [isSmall, setisSmall] = useState(false);

  const postMessageReceived = (e) => {
    if (e.data.type === 'zoomProps') setzoomProps(e.data.zoomProps);
    if (e.data.type === 'readyToZoom') window.top.postMessage({ type: "getZoomProps" }, "*");
    if (e.data.type === 'frameSize') setisSmall(e.data.isSmall);
  };

  useEffect(() => {
    window.addEventListener("message", postMessageReceived);
    if (!zoomProps) window.top.postMessage({ type: "getZoomProps" }, "*");

    return () => {
      window.removeEventListener("message", postMessageReceived);
    };
  }, []);

  if (!zoomProps) return "Loading...";
  
  return <Zoom isSmall={isSmall} {...zoomProps} />;
}
