/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import { css } from "@emotion/react";

export default function ZoomOver() {
  useEffect(() => {
    window.top.postMessage({ type: "zoomExited" }, "*");
  }, []);

  return (
    <div css={css`height: 100%; width: 100%;`}>
      This meeting has been exited
    </div>
  );
}
