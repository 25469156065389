/** @jsxImportSource @emotion/react */
import React, { useRef, useEffect } from "react";
import { css } from "@emotion/react";
import { ZoomMtg } from "@zoomus/websdk";
import "@zoomus/websdk/dist/css/react-select.css";

const ZOOM_API_KEY = "9Oloo52mQP6r-nm2AefxqQ";

const smallViewStyles = `
.full-screen-icon {
  display: none;
}

.meeting-info-container {
  display: none;
}

.gallery-video-container__main-view {
  margin-top: 0 !important;
}

.gallery-video-container__video-frame {
  height: 100% !important;
  width: 100% !important;
}

#wc-footer {
  display: none;
}

.join-dialog {
  height: min-content;
  padding-bottom: 50px;
}
`;

export default function ZoomEmbed(props) {
  const { isSmall, id, signature, userName, userEmail, password } = props;
  const zoomRoot = useRef(null);

  const unloadZoom = () => {
    const maybeZoom = document.getElementById("zmmtg-root");
    maybeZoom.remove();
  };

  const loadZoom = () => {
    const maybeZoom = document.getElementById("zmmtg-root");
    if (!maybeZoom) return;
    zoomRoot.current?.appendChild(maybeZoom);

    ZoomMtg.init({
      leaveUrl: `${process.env.REACT_APP_URL}/exit`,
      isSupportAV: true,
      success: (success) => {
        ZoomMtg.join({
          signature,
          meetingNumber: id,
          userName,
          apiKey: ZOOM_API_KEY,
          userEmail,
          passWord: password,
          success: (success) => {
            // console.log(success);
          },
          error: (error) => {
            // console.log(error);
          },
        });
      },
      error: (error) => {
        // console.log(error);
      },
    });
  };

  useEffect(() => {
    // unloadZoom();
    loadZoom();
  }, []);

  if (!id || !signature) return <div>Zoom meeting details missing</div>;

  return (
    <div
      css={css`
        ${isSmall ? smallViewStyles : ""}
      `}
      ref={zoomRoot}
    />
  );
}
